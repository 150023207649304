import React, { FC, useCallback, useEffect, useMemo, useState, createContext } from 'react';
import qs from 'qs';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import IdeaCard from 'components/Ideas/IdeaCard';
import DetailModal from 'components/Ideas/DetailModal';
import AuthModal from 'components/Ideas/AuthModal';
import queryString from 'query-string';
import Cactus from 'components/icons/Cactus';
import MagnifyingLongHair from 'components/icons/MagnifyingLongHair';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Alert,
  Card,
  Skeleton,
  Typography,
  Image,
  Space,
} from 'antd';

const { Text } = Typography;
const queryClient = new QueryClient();

export const ProjectContext = createContext({
  projectId: null,
  projectName: null,
  currentSubscriberEmail: null,
  canVote: false,
  categoriesEnabled: false,
  voteCountDisplay: 'hidden',
  votingEnabled: false,
  feedbackEnabled: false,
  recaptchaSiteToken: null,
  pathPrefix: null,
  primaryColor: '#f5f6f7',
  primaryTextColor: '#0E2C3E',
  secondaryTextColor: '#BAB9B9',
  whiteColor: '#FFFFFF',
  offWhiteColor: '#F3F6F8',
  lightGrayColor: '#E8EBEF',
  grayColor: '#D4D5D8',
  authReason: 'feedback',
  privacyPolicyUrl: 'https://www.launchnotes.com/privacy',
  termsAndConditionsUrl: 'https://www.launchnotes.com/terms'
});

type ThemeProps= {
  primaryColor: string;
  secondaryColor: string;
  grayColor: string;
  lightGrayColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  whiteColor: string;
  offWhiteColor: string;
  strokeColor?: string;
}

type IdeasProps= {
  categoriesEnabled: boolean;
  theme: ThemeProps;
  feedbackEnabled: boolean;
  votingEnabled: boolean;
  pathPrefix: string;
  projectId: string;
  projectName: string;
  canVote: boolean;
  voteCountDisplay: string
  currentSubscriberEmail: string;
  recaptchaSiteToken: string;
  emptyStateImage?: string;
  privacyPolicyUrl: string;
  termsAndConditionsUrl: string;
};

const LoadingState = (
  <>
    {[...Array(3)].map((_:undefined, index:number) => (
      <Card key={index} style={{ height: '90px' }} className="idea white-background mb-1">
        <Skeleton paragraph={false} />
      </Card>
    ))}
  </>
);

const Ideas: FC<IdeasProps> = ({
  projectId,
  projectName,
  pathPrefix,
  currentSubscriberEmail,
  canVote,
  categoriesEnabled,
  voteCountDisplay,
  theme,
  feedbackEnabled,
  votingEnabled,
  recaptchaSiteToken,
  emptyStateImage,
  privacyPolicyUrl,
  termsAndConditionsUrl
}) => {
  const [showDetail, updateShowDetail] = useState<string>();
  const [showAuthModal, updateShowAuthModal] = useState<boolean>(false);
  const { primaryColor, primaryTextColor, secondaryTextColor, offWhiteColor, whiteColor, grayColor, lightGrayColor } = theme;

  const projectContext = useMemo(() => ({
    projectId,
    projectName,
    currentSubscriberEmail,
    canVote,
    categoriesEnabled,
    voteCountDisplay,
    votingEnabled,
    feedbackEnabled,
    recaptchaSiteToken,
    pathPrefix,
    primaryColor,
    primaryTextColor,
    secondaryTextColor,
    offWhiteColor,
    whiteColor,
    grayColor,
    lightGrayColor,
    privacyPolicyUrl,
    termsAndConditionsUrl,
    authReason: 'feedback',
  }), []);

  const { isLoading, error, data } = useQuery({
    queryKey: ['idea', 'list'],

    queryFn: () => {
      const existingParams = queryString.parse(location.search);
      const fetchUrl = `${window.location.origin + window.location.pathname}.json?` + queryString.stringify(existingParams);

      return fetch(fetchUrl)
        .then(res => res.json())
        .then(body => body?.ideas);
    }
  });

  useEffect(() => {
    const { detail } = qs.parse(window.location.search.replace('?', ''));
    if (detail) {
      updateShowDetail(detail as string);
    }
  }, []);

  const setDetailQueryParam = useCallback((id) => {
    if (history.pushState) {
      const query = {
        ...qs.parse(window.location.search.replace('?', '')),
        detail: id,
      };
      const path = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qs.stringify(query)}`;
      window.history.pushState({}, '', path);

      window.Turbo.navigator.history.push(path);
    }
  }, []);

  const openDetailView = useCallback((id) => {
    document.body.style.overflowY = 'hidden';
    setDetailQueryParam(id);
    updateShowDetail(id);
  }, [setDetailQueryParam, updateShowDetail]);

  const closeDetailView = useCallback(() => {
    document.body.style.overflowY = 'scroll';
    setDetailQueryParam(undefined);
    updateShowDetail(undefined);
  }, [setDetailQueryParam, updateShowDetail]);

  if (isLoading) {
    return LoadingState;
  }

  if (error) {
    return <Alert description={`There was an error: ${error}`} />;
  }

  const handleEmptyState = () => {
    let image;
    if (emptyStateImage) {
      image = <Image height={120} src={emptyStateImage} alt="empty content image" className="empty-state-illustration" />;
    } else {
      const stockImages = [<Cactus theme={theme} />, <MagnifyingLongHair theme={theme} />];
      image = stockImages[Math.floor(Math.random() * stockImages.length)];
    }

    return (
      <Space className="empty-ideas">
        { image }
        <Text className="opacity-8" style={{ fontSize: 18, color: '#3D3D3D' }}>
          There are no ideas that match this criteria
        </Text>
      </Space>
    );
  };

  return (
    <ProjectContext.Provider value={projectContext}>
      <div id="ideas-container-component">
        { !data.length ?
          handleEmptyState() :
          data.map((idea) => (
            <IdeaCard
              idea={idea}
              openDetailView={openDetailView}
              updateShowAuthModal={updateShowAuthModal}
              key={idea.id}
            />
          ))}
        <DetailModal
          id={showDetail}
          onChange={openDetailView}
          onClose={closeDetailView}
        />
        <AuthModal
          visible={showAuthModal}
          onClose={updateShowAuthModal}
        />
      </div>
    </ProjectContext.Provider>
  );
};

export default function App(props) {
  const {
    development,
    projectId,
    projectName,
    pathPrefix,
    theme,
    currentSubscriberEmail,
    canVote,
    categoriesEnabled,
    voteCountDisplay,
    feedbackEnabled,
    votingEnabled,
    recaptchaSiteToken,
    emptyStateImage,
    privacyPolicyUrl,
    termsAndConditionsUrl,
  } = props;

  return (
    <QueryClientProvider client={queryClient}>
      <Ideas
        projectId={projectId}
        projectName={projectName}
        pathPrefix={pathPrefix}
        currentSubscriberEmail={currentSubscriberEmail}
        canVote={canVote}
        categoriesEnabled={categoriesEnabled}
        voteCountDisplay={voteCountDisplay}
        theme={theme}
        feedbackEnabled={feedbackEnabled}
        votingEnabled={votingEnabled}
        recaptchaSiteToken={recaptchaSiteToken}
        emptyStateImage={emptyStateImage}
        privacyPolicyUrl={privacyPolicyUrl}
        termsAndConditionsUrl={termsAndConditionsUrl}
      />
      { development && <ReactQueryDevtools initialIsOpen={false} position={'bottom'}/> }
    </QueryClientProvider>
  );
}
