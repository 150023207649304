import React, { FC, useContext } from 'react';
import { CategoryBadgeList } from 'components/categories/Badge';
import { ProjectContext } from 'components/Ideas/index';
import {
  Space,
  Typography,
} from 'antd';

const { Paragraph, Title } = Typography;

type Props = {
  idea: LN.Idea,
}

const DetailModalContent: FC<Props> = ({ idea }) => {
  const context = useContext(ProjectContext);

  return (
    <Space direction="vertical" size="middle" className="idea-content">
      { context.categoriesEnabled &&
        <CategoryBadgeList categories={idea.categories} />
      }
      <Title
        className="idea-title primary-text-color"
        style={{ color: context.primaryTextColor }}
      >
        {idea.name}
      </Title>
      {idea.notes &&
        <Paragraph
          className="idea-notes primary-text-color"
          style={{ color: context.primaryTextColor }}
        >
          {idea?.notes}
        </Paragraph>
      }
    </Space>
  );
};

export default DetailModalContent;
