import React from 'react';

const Liftoff = (props) => (
  <svg width="0.886075949em" height="1em" viewBox="0 0 70 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path d="M39.1607 62.3796C56.1927 62.3796 69.9999 48.4154 69.9999 31.1898C69.9999 13.9641 56.1927 0 39.1607 0C22.1287 0 8.32153 13.9641 8.32153 31.1898C8.32153 48.4154 22.1287 62.3796 39.1607 62.3796Z" fill="#F29272" />
      <path d="M22.4473 64.642C21.748 67.0467 18.741 74.8264 6.78299 78.9992C6.78299 78.9992 5.03474 67.5417 12.5872 57.7109C12.8669 58.2767 13.1466 58.7718 13.4963 59.2669C14.0557 60.0449 14.6851 60.7521 15.4543 61.3179C14.6152 63.086 13.8459 65.2078 13.1466 67.6125C15.1047 65.9858 16.7131 64.4298 18.1117 63.0153C18.6711 63.369 19.3005 63.6519 19.79 63.864C20.1396 64.0055 20.4893 64.1469 20.8389 64.2177C21.3284 64.4298 21.8879 64.5713 22.4473 64.642Z" fill="#F29272" />
      <path d="M17.2726 57.711C16.9928 58.1353 16.7831 58.6304 16.5033 59.1255C16.4334 59.2669 16.3635 59.3377 16.2935 59.4791C16.0138 60.0449 15.7341 60.6814 15.4544 61.318C14.6852 60.6814 14.0558 60.0449 13.4963 59.2669C13.1467 58.7719 12.797 58.2768 12.5873 57.711C11.6082 55.8014 11.1887 53.5382 11.4684 51.3457C11.7481 51.4164 11.9579 51.5579 12.2376 51.6993C12.6572 51.9115 13.0768 51.7701 13.4264 51.3457C14.0558 53.7504 15.3845 56.0136 17.2726 57.711Z" fill="#C9C7C5" />
      <path d="M28.1119 63.1568C26.4336 64.2884 24.4056 64.7835 22.4476 64.5713C21.8881 64.5006 21.3287 64.4299 20.7693 64.2177C20.4196 64.0763 20.07 64.0055 19.7203 63.8641C19.1609 63.5812 18.6014 63.2983 18.042 63.0154C18.4616 62.591 18.8112 62.1667 19.1609 61.7423C19.2308 61.6716 19.3707 61.5301 19.4406 61.4594C19.8602 60.9643 20.2098 60.54 20.5595 60.0449C22.5175 61.0351 24.6154 61.4594 26.6434 61.4594V61.5301C26.7133 61.6716 26.7833 61.813 26.8532 61.9545C26.9231 62.0252 26.993 62.0959 27.063 62.1667C27.4826 62.4496 27.8322 62.8032 28.1119 63.1568Z" fill="#C9C7C5" />
      <path d="M13.4265 51.3461C13.0768 51.7705 12.6573 51.9119 12.2377 51.6997C11.958 51.5583 11.7482 51.4876 11.4684 51.3461C7.34257 49.6487 3.35655 50.0731 0.419492 50.9925C0.349562 50.9925 0.279632 50.9925 0.209702 50.9218C-0.489599 50.2145 1.74816 44.0614 2.51739 42.7176C3.0069 41.9397 3.84607 41.4446 4.75516 41.3739C6.85306 41.2324 10.5594 41.091 12.4475 41.2324C12.5873 41.2324 12.7971 41.2324 12.937 41.2324L14.3356 41.3739C13.7062 42.7176 13.2866 44.1321 13.0768 45.5467C12.7272 47.5977 12.867 49.5073 13.4265 51.3461Z" fill="#C9C7C5" />
      <path d="M39.0907 66.8346C38.811 68.4613 34.825 72.0683 32.9369 72.917C32.9369 72.917 32.9369 72.917 32.8669 72.917C32.797 72.917 32.797 72.917 32.7271 72.9877C32.6572 72.9877 32.6572 72.9877 32.5872 73.0584C32.5173 73.0584 32.4474 73.1291 32.3774 73.1291C32.3774 73.1291 32.3075 73.1291 32.2376 73.1291C32.0977 73.1291 31.9579 73.0584 31.8879 72.917C31.8879 72.917 31.8879 72.917 31.8879 72.8462C31.6781 70.3709 30.9788 67.3297 29.0907 64.5714C28.811 64.147 28.5313 63.7227 28.1816 63.3691C28.1816 63.2983 28.1117 63.2983 28.0418 63.2276C28.0418 63.2276 28.0418 63.2276 27.9718 63.1569C27.6921 62.8033 27.3425 62.4496 26.9928 62.096C26.9229 62.0253 26.853 61.9546 26.783 61.8838C26.6432 61.7424 26.5732 61.6717 26.5732 61.4595V61.3888C29.0208 61.3888 31.4683 60.6815 33.6362 59.3377C34.5453 58.7719 35.3145 58.1354 36.0837 57.3574L36.1537 57.4281C36.2236 57.5696 36.2935 57.7111 36.3635 57.8525C37.7621 60.823 39.6502 63.7934 39.0907 66.8346Z" fill="#C9C7C5" />
      <path d="M46.853 38.1909C46.783 38.403 46.783 38.6152 46.7131 38.8274C46.6432 39.181 46.5033 39.6054 46.3635 39.959C45.5243 43.0002 44.3355 45.6877 43.0068 47.5973L41.9579 49.224L41.2586 50.2849L37.6222 55.7307C37.2026 56.2965 36.7131 56.8623 36.2236 57.3574C35.4544 58.1354 34.6851 58.7719 33.776 59.3377C31.6781 60.6815 29.2306 61.3887 26.7131 61.3887C24.6152 61.3887 22.5173 60.9644 20.6292 59.9742C22.797 57.0745 23.846 54.6698 24.2655 53.0431C24.4054 52.4066 24.5453 51.9115 24.6152 51.4872C24.6851 50.9214 24.1257 50.5678 23.6362 50.7799C23.2865 50.9921 22.797 51.275 22.2376 51.6994C20.9089 52.7602 19.0907 54.5284 17.2725 57.711C15.3844 55.9429 14.1257 53.7504 13.4264 51.3457C12.8669 49.5069 12.7271 47.5266 13.0068 45.617C13.2166 44.2025 13.6362 42.788 14.2655 41.4442C14.4753 40.9491 14.7551 40.5248 15.0348 40.1004L20.4893 31.967C22.0278 29.6331 24.6152 27.1577 27.6921 24.8945C29.79 27.865 32.6572 30.7647 36.2236 33.2401C39.6502 35.6448 43.3565 37.2714 46.853 38.1909Z" fill="#F9FAFA" />
      <path d="M48.1118 28.3603C48.1118 28.5725 48.1118 28.8554 48.1118 29.0676C48.0419 30.4821 47.972 31.8966 47.7622 33.3111C47.6922 33.6647 47.6922 34.0183 47.6223 34.3012C47.4125 35.645 47.1328 36.9181 46.8531 38.1911C43.3566 37.3424 39.6503 35.645 36.1538 33.1696C32.5873 30.6942 29.7202 27.8652 27.6223 24.8241C27.6922 24.7533 27.8321 24.6826 27.902 24.6119C28.0419 24.4704 28.1818 24.3997 28.3216 24.2583C28.6013 24.0461 28.8111 23.9046 29.0908 23.6925C29.3706 23.4803 29.7202 23.2681 29.9999 23.1267C30.2097 22.9852 30.4894 22.8437 30.6992 22.7023C30.979 22.4901 31.3286 22.3487 31.6083 22.1365C31.888 21.995 32.2377 21.7829 32.5174 21.6414C32.7971 21.5 33.1468 21.2878 33.4265 21.1463C33.7062 21.0049 33.916 20.8634 34.1957 20.7927C34.3356 20.722 34.5454 20.6513 34.6852 20.5805C34.895 20.4391 35.1048 20.3684 35.3846 20.2976C35.5943 20.2269 35.8041 20.1562 36.0139 20.0147C36.1538 19.944 36.3636 19.8733 36.5734 19.8026C37.3426 19.4489 38.1818 19.166 38.951 18.9539C39.1608 18.8831 39.3706 18.8124 39.5804 18.7417C39.8601 18.671 40.1398 18.6002 40.4195 18.5295C40.6992 18.4588 40.979 18.3881 41.2587 18.3173C41.5384 18.2466 41.8181 18.1759 42.0978 18.1759C42.3076 18.1052 42.5174 18.1052 42.6573 18.0344C42.8671 17.9637 43.0069 17.9637 43.2167 17.9637C43.4964 17.893 43.7762 17.893 44.0559 17.893C44.8251 17.8223 45.5943 17.7515 46.2936 17.8223H46.3636C46.3636 17.8223 46.3636 17.893 46.4335 17.893H46.5034L46.5734 17.9637C46.7832 18.4588 46.9929 19.0246 47.1328 19.5904C47.2727 19.944 47.3426 20.3684 47.4825 20.722C47.5524 20.9342 47.5524 21.1463 47.6223 21.2878C47.6922 21.6414 47.7622 21.995 47.8321 22.4194C47.8321 22.4901 47.8321 22.5608 47.8321 22.6316C47.902 22.9852 47.972 23.3388 47.972 23.6925C48.1118 25.1777 48.1818 26.7336 48.1118 28.3603Z" fill="#C9C7C5" />
      <path d="M47.6223 34.3001C47.4125 35.6439 47.1328 36.9169 46.8531 38.19C43.3566 37.3413 39.6503 35.6439 36.1538 33.1685C32.5873 30.6931 29.7202 27.8641 27.6223 24.8229C27.6922 24.7522 27.8321 24.6815 27.902 24.6108C28.0419 24.4693 28.1818 24.3986 28.3216 24.2571C28.6013 24.045 28.8111 23.9035 29.0908 23.6913C29.3706 23.4791 29.7202 23.267 29.9999 23.1255C30.2097 22.9841 30.4894 22.8426 30.6992 22.7012C32.6573 25.1766 35.1748 27.5812 38.1818 29.6322C41.2587 31.8247 44.5454 33.3807 47.6223 34.3001Z" fill="#C9C7C5" />
      <path d="M47.6223 34.3009C48.5314 28.5014 48.3915 22.3484 46.5034 17.8927L46.4335 17.822H46.3636C46.3636 17.822 46.3636 17.7512 46.2936 17.7512L46.2237 17.6805C41.3985 17.539 35.6643 19.6608 30.6992 22.702C32.6573 25.1774 35.1747 27.582 38.1817 29.6331C41.2587 31.8255 44.4754 33.3815 47.6223 34.3009Z" fill="#F9FAFA" />
      <path d="M24.5454 51.5582C24.4755 51.9825 24.4056 52.4776 24.1958 53.1141C23.7762 54.8115 22.7273 57.1455 20.5594 60.0452C20.2098 60.4696 19.8601 60.9646 19.4405 61.4597C19.3706 61.5304 19.3007 61.6719 19.1608 61.7426C18.8112 62.167 18.3916 62.5913 18.0419 63.0157C16.7133 64.4302 15.0349 65.9861 13.0769 67.6128C13.8461 65.2081 14.6154 63.0864 15.3846 61.3183C15.6643 60.6817 15.944 60.0452 16.2238 59.4794C16.2937 59.3379 16.3636 59.2672 16.4335 59.1258C16.7133 58.6307 16.9231 58.1356 17.2028 57.7113C19.021 54.5286 20.7692 52.7605 22.1678 51.6996C22.7273 51.2753 23.1468 50.9924 23.5664 50.7802C24.0559 50.568 24.6154 50.9924 24.5454 51.5582Z" fill="#C9C7C5" />
      <path d="M30.3496 48.0231C26.5034 48.0231 23.4265 44.9112 23.4265 41.0213C23.4265 37.1314 26.5034 34.0195 30.3496 34.0195C34.1957 34.0195 37.2727 37.1314 37.2727 41.0213C37.2727 44.9112 34.1957 48.0231 30.3496 48.0231ZM30.3496 35.434C27.3426 35.434 24.8251 37.9094 24.8251 41.0213C24.8251 44.1332 27.2727 46.6086 30.3496 46.6086C33.4265 46.6086 35.8741 44.1332 35.8741 41.0213C35.8741 37.9094 33.4265 35.434 30.3496 35.434Z" fill="#78716C" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="70" height="79" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Liftoff;
