import React, { useState, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import RssFeedIcon from './RssFeedIcon';
import styled from '@emotion/styled';

export const Container = styled.div`
  border-radius: 6px;

  a {
    &:active, &:focus {
      outline: none;
      border: none;
      -moz-outline-style: none;
      box-shadow: none;
    }
  }
`;

const RssFeedButton = ({url, isWorkItem = false, translations}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.categories) {
      setCategories(query.categories.split(','));
    }
  }, [window.location]);

  const feedUrl = useMemo(() => {
    if (categories.length > 0) {
      return `${url}?categories=${categories.join('%2C')}`;
    } else {
      return url;
    }
  }, [url, categories]);

  return (
    <Container className='light-gray-background' style={{marginTop: '20px', padding: '10px'}}>
      <a href={feedUrl} target='_blank' className="btn" style={{width: "100%", textAlign: "left"}}>
        <span style={{display: 'inline-flex'}}>
          <div className="mr-2"><RssFeedIcon /></div>
          <strong>{translations.feedTitle}</strong>
        </span>
        { isWorkItem ? (
          <div>{translations.subscribeWorkItem}</div>
        ) : (
          <div>
            { categories?.length > 0 ?
              translations.subscribeSelectedCategories :
              translations.subscribeAllCategories
            }
          </div>
        )}
      </a>
    </Container>
  );
};

export default RssFeedButton;
