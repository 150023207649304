import React, { FC, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AuthPrompt, { VoteTriggers } from 'components/Ideas/AuthPrompt';
import DetailModalSidebar from 'components/Ideas/DetailModalSidebar';
import DetailModalContent from 'components/Ideas/DetailModalContent';
import FeedbackForm from 'components/Ideas/FeedbackForm';
import MobileNavbar from 'components/Ideas/MobileNavbar';
import NavArrows from 'components/Ideas/NavArrows';
import CloseIcon from 'components/core/CloseIcon';
import { useResponsive } from 'ahooks';
import { ProjectContext } from 'components/Ideas/index';
import {
  Col,
  ConfigProvider,
  Drawer,
  Modal,
  Row,
  Skeleton,
  Space,
} from 'antd';

const getIdeaById = async (id) => {
  const fetchUrl = `${window.location.origin + window.location.pathname}/${id}.json?`;
  const { data } = await axios.get(fetchUrl);
  return data;
};

function useIdea(id) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['idea', id],
    queryFn: () => getIdeaById(id),
    enabled: !!id,
    initialDataUpdatedAt: () => queryClient.getQueryState(['idea', 'list'])?.dataUpdatedAt,

    initialData: () => {
      const data = queryClient.getQueryData<LN.Idea[]>(['idea', 'list']);
      return data.find((idea) => idea.id === id);
    }
  });
}

type DetailModalProps = {
  id: string;
  onClose: () => void;
  onChange: (id: string) => void;
}

const DetailModal: FC<DetailModalProps> = ({ id, onClose, onChange }) => {
  const context = useContext(ProjectContext);
  const queryClient = useQueryClient();
  const { isFetching, data } = useIdea(id);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [showAuthView, updateShowAuthView] = useState<boolean>(false);
  const [authTrigger, setAuthTrigger] = useState<VoteTriggers>('vote');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const actionsEnabled = context.votingEnabled || context.feedbackEnabled;
  const responsive = useResponsive();
  const mobileView = !responsive.md;

  const listData = queryClient.getQueryData<LN.Idea[]>(['idea', 'list']);
  const listIdea = listData.find((idea) => idea.id === id);

  useEffect(() => {
    updateShowAuthView(false);
    setSubmitted(false);
  }, [id]);

  const DesktopView = (
    <Row className="idea-content-row ">
      { actionsEnabled &&
        <DetailModalSidebar
          id={id}
          showAuthView={showAuthView}
          updateShowAuthView={updateShowAuthView}
          submitted={submitted}
          setSubmitted={setSubmitted}
          authTrigger={authTrigger}
          setAuthTrigger={setAuthTrigger}
        />
      }
      <Col
        className="h-100"
        span={actionsEnabled ? '18' : '24'}
        style={{ backgroundColor: context.whiteColor }}
      >
        { isFetching || !data ?
          <Skeleton className="p-5" /> :
          <>
            <DetailModalContent idea={data.idea} />
            <NavArrows
              listIdea={listIdea}
              onChange={onChange}
            />
          </>
        }
      </Col>
    </Row>
  );

  const MobileView = (
    <Space
      direction="vertical"
      size="middle"
      id="idea-mobile-container"
      className="idea-mobile-container w-100 h-100"
    >
      { isFetching || !data ? <Skeleton className="p-3" /> :
      <>
        <Row className="white-color">
          <DetailModalContent idea={data.idea} />
        </Row>
        <MobileNavbar
          id={id}
          listIdea={listIdea}
          onChange={onChange}
          updateShowAuthView={updateShowAuthView}
          setAuthTrigger={setAuthTrigger}
          setDrawerOpen={setDrawerOpen}
        />
        <Drawer
          rootClassName="idea-mobile-sidebar"
          placement="bottom"
          closable={false}
          open={drawerOpen}
          zIndex={1250}
          contentWrapperStyle={{ height: '400px' }}
          drawerStyle={{ backgroundColor: showAuthView ? context.primaryColor : context.offWhiteColor }}
          headerStyle={{ backgroundColor: showAuthView ? context.primaryColor : context.offWhiteColor }}
          onClose={() => setDrawerOpen(false)}
          getContainer={() => document.getElementById('idea-mobile-container')}
        >
          {showAuthView ?
            <AuthPrompt trigger={authTrigger} /> :
            <FeedbackForm
              id={id}
              showAuthView={updateShowAuthView}
              submitted={submitted}
              setSubmitted={setSubmitted}
              setAuthTrigger={setAuthTrigger}
            />
          }
        </Drawer>
      </> }
    </Space>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: context.whiteColor,
          },
        },
      }}
    >
      <Modal
        centered
        open={!!id}
        footer={null}
        zIndex={1200}
        className={`idea-modal col-lg-8 ${mobileView ? 'mobile' : 'desktop'}`}
        getContainer={document.getElementById('ideas-container-component')}
        closeIcon={<CloseIcon color={context.secondaryTextColor} hoverColor={context.primaryColor} />}
        onCancel={() => {
          setDrawerOpen(false);
          onClose();
        }}
      >
        {mobileView ? MobileView : DesktopView}
      </Modal>
    </ConfigProvider>
  );
};

export default DetailModal;
