/* eslint-disable camelcase */
import React, { FC, useMemo, useState } from 'react';
import qs from 'qs';

const PoweredBy: FC<{ utm_content?: string, color?:string, hoverColor?:string }> = ({ utm_content = 'ideas-index', color, hoverColor }) => {
  const [textColor, setTextColor] = useState<string>(color);

  const params = useMemo(() => ({
    utm_content,
    utm_campaign: window && window.location.origin,
    utm_medium: 'powered-by',
    utm_source: 'in-app',
  }), [utm_content]);

  const styles = color ? { color: textColor } : {};

  return (
    <a
      style={styles}
      onMouseEnter={() => hoverColor && setTextColor(hoverColor)}
      onMouseLeave={() => color && setTextColor(color)}
      className="primary-text-color powered-by"
      href={`https://www.launchnotes.com/?${qs.stringify(params)}`}
    >
      Powered by LaunchNotes
    </a>
  );
};

export default PoweredBy;
