import React, { FC, useContext } from 'react';
import FeedbackForm from 'components/Ideas/FeedbackForm';
import VoteIndicator from 'components/Ideas/VoteIndicator';
import AuthPrompt, { VoteTriggers } from 'components/Ideas/AuthPrompt';
import { ProjectContext } from 'components/Ideas/index';
import {
  Col,
  Divider,
  Space,
  Row,
} from 'antd';

type Props = {
  id: string;
  showAuthView: boolean;
  updateShowAuthView: (boolean) => void;
  submitted: boolean;
  setSubmitted: (boolean) => void;
  authTrigger: VoteTriggers;
  setAuthTrigger: (string) => void;
}

const DetailModalSidebar: FC<Props> = ({ id, showAuthView, updateShowAuthView, submitted, setSubmitted, authTrigger, setAuthTrigger }) => {
  const context = useContext(ProjectContext);
  const bgColor = showAuthView ? context.primaryColor : context.offWhiteColor;

  return (
    <Col span="6" className="idea-sidebar" style={{ backgroundColor: bgColor }}>
      { showAuthView ?
        <AuthPrompt trigger={authTrigger} /> :
        <Space direction="vertical" className="idea-sidebar-content w-100">
          { context.votingEnabled &&
            <>
              <Row className="voting-row">
                <VoteIndicator
                  ideaId={id}
                  detailView
                  showAuthView={updateShowAuthView}
                  setAuthTrigger={setAuthTrigger}
                />
              </Row>
              <Divider style={{ color: context.lightGrayColor }} />
            </>
          }
          <Row className="idea-feedback-wrapper">
            <FeedbackForm
              id={id}
              showAuthView={updateShowAuthView}
              submitted={submitted}
              setSubmitted={setSubmitted}
              setAuthTrigger={setAuthTrigger}
            />
          </Row>
        </Space>
      }
    </Col>
  );
};

export default DetailModalSidebar;
