import React, { FC } from 'react';

const Badge: FC<LN.Category> = ({name, description, color}) => (
  <span
    className="category-badge d-flex align-items-center mr-3"
    style={{fontSize: 12}}
  >
    <svg
      className="category-dot"
      width="9px"
      height="9px"
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="6"
        fill={color}
      />
    </svg>
    <span
      className="category-title secondary-text-color text-uppercase"
      style={{ letterSpacing: 0.2 }}
    >
      {name}
    </span>
  </span>
);

export const CategoryBadgeList: FC<{ categories: LN.Category[] }> = ({ categories }) => {
  if (!categories) return;

  return (
    <div className="categories d-flex flex-wrap mb-n1">
      {categories.map((category, index) => <Badge {...category} key={`category-${index}`} />)}
    </div>
  );
};

export default Badge;