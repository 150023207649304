/* eslint-disable camelcase */
import React, { FC, useContext } from 'react';
import VoteIndicator from 'components/Ideas/VoteIndicator';
import { CategoryBadgeList } from 'components/categories/Badge';
import { ProjectContext } from 'components/Ideas/index';
import {
  Card,
  Col,
  Row,
  Typography,
} from 'antd';

const { Title } = Typography;

type Props = {
  idea: LN.Idea,
  openDetailView: (id) => void;
  updateShowAuthModal: (boolean) => void;
}

const IdeaCard: FC<Props> = ({ idea, openDetailView, updateShowAuthModal }) => {
  const context = useContext(ProjectContext);
  const { name, id, categories } = idea;

  return (
    <Card
      key={id}
      className="idea"
      onClick={() => openDetailView(id)}
      style={{
        cursor: 'pointer',
        backgroundColor: context.whiteColor,
      }}
    >
      { context.categoriesEnabled &&
        <CategoryBadgeList categories={categories} />
      }
      <Title
        level={4}
        className="my-2 card-title primary-text-color"
        style={{ color: context.primaryTextColor }}
      >
        {name}
      </Title>
      {(context.votingEnabled || context.feedbackEnabled) &&
        <Row>
          {context.votingEnabled &&
            <Col span={6} className="idea-card-vote">
              <VoteIndicator
                ideaId={id}
                showAuthView={updateShowAuthModal}
              />
            </Col>
          }
        </Row>
      }
    </Card>
  );
};

export default IdeaCard;
