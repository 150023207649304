import React, { FC, useContext, useState, useEffect } from 'react';
import SelectLow from 'components/icons/SelectLow';
import SelectMedium from 'components/icons/SelectMedium';
import SelectHigh from 'components/icons/SelectHigh';
import Liftoff from 'components/icons/Liftoff';
import PoweredBy from 'components/PoweredBy';
import { ProjectContext } from 'components/Ideas/index';
import useSubmitFeedback, { FeedbackAttrs } from '../core/hooks/useSubmitFeedback';
import {
  Button,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';

const { Option } = Select;
const { TextArea } = Input;
const { Text, Title } = Typography;

type Props = {
  id: string;
  showAuthView: (boolean) => void;
  submitted: boolean;
  setSubmitted: (boolean) => void;
  setAuthTrigger: (string) => void;
}

const FeedbackForm: FC<Props> = ({ id, showAuthView, submitted, setSubmitted, setAuthTrigger }) => {
  const {
    canVote,
    currentSubscriberEmail,
    lightGrayColor,
    pathPrefix,
    primaryColor,
    primaryTextColor,
    projectId,
    secondaryTextColor,
    whiteColor,
  } = useContext(ProjectContext);

  const [submitFeedback] = useSubmitFeedback(!!pathPrefix ? `${pathPrefix}/feedbacks.json` : '/feedbacks.json');

  const [importance, setImportance] = useState<string | null>();
  const [content, setContent] = useState<string | null>();
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    setSubmitted(false);
    setImportance(null);
    setContent(null);
  }, [id]);

  const authenticate = () => {
    setAuthTrigger('feedback');
    showAuthView(true);
  };

  const submitForm = () => {
    if (!canVote) {
      authenticate();
    } else if (!content) {
      setError('Please provide your feedback.');
    } else {
      setError(null);
      const submitValues:FeedbackAttrs = {
        project: projectId,
        feedbackable_id: id,
        feedbackable_type: 'Idea',
        email: currentSubscriberEmail,
        content,
        importance,
      };

      submitFeedback(submitValues);
      setSubmitted(true);
      setImportance(null);
      setContent(null);
    }
  };

  const handleFocus = () => canVote || authenticate();

  const handleInput = (inputFxn:(string) => void, value:string) => {
    if (canVote) {
      inputFxn(value);
    } else {
      authenticate();
    }
  };

  if (submitted) {
    return (
      <Space
        direction="vertical"
        align="center"
        className="feedback-success mt-5 mx-auto w-100"
      >
        <Liftoff style={{ fontSize: 48 }} />
        <Title level={4} className="feedback-success">Thanks for your feedback!</Title>
      </Space>
    );
  }

  return (
    <Space
      direction="vertical"
      className="idea-feedback-form w-100"
    >
      <Row className="mb-2 h-100">
        <Text
          className="mb-1"
          style={{ color: secondaryTextColor }}
        >
          Your feedback*
        </Text>
        <TextArea
          rows={4}
          value={content}
          autoSize={{ minRows: 6, maxRows: 8 }}
          onFocus={() => handleFocus()}
          onClick={() => handleFocus()}
          onChange={(event) => handleInput(setContent, event.target.value)}
          onPressEnter={() => submitForm()}
          style={{
            color: primaryTextColor,
            borderColor: lightGrayColor,
            backgroundColor: whiteColor,
          }}
        />
        { error && <Text className="feedback-form-error" type="danger">{error}</Text> }
      </Row>
      <Row className="mb-2">
        <Text
          className="mb-1"
          style={{ color: secondaryTextColor }}
        >
          How important is this?
        </Text>
        <Select
          allowClear
          autoFocus
          value={importance}
          className="w-100 feedback-importance-selector"
          onClick={() => handleFocus()}
          onSelect={(value: string) => handleInput(setImportance, value)}
          onClear={() => setImportance(null)}
          getPopupContainer={() => document.getElementById('ideas-container-component')}
          style={{
            backgroundColor: whiteColor,
            borderColor: lightGrayColor,
          }}
          dropdownStyle={{
            zIndex: 1500,
            color: primaryTextColor,
            backgroundColor: whiteColor,
          }}
        >
          <Option
            value="low"
            key="low"
            className="low-option"
            style={{ color: primaryTextColor }}
          >
            <SelectLow /> Nice to have
          </Option>
          <Option
            value="medium"
            key="medium"
            className="medium-option"
            style={{ color: primaryTextColor }}
          >
            <SelectMedium /> Somewhat
          </Option>
          <Option
            value="high"
            key="high"
            className="high-option"
            style={{ color: primaryTextColor }}
          >
            <SelectHigh /> Extremely
          </Option>
        </Select>
      </Row>
      <Row className="my-2">
        <Button
          style={{
            color: content ? `${primaryColor}` : `${primaryColor}80`,
            backgroundColor: content ? `${whiteColor}` : `${whiteColor}80`,
          }}
          className="fb-submit w-100"
          onClick={() => submitForm()}
          disabled={!content}
        >
          Submit
        </Button>
      </Row>
      <Row>
        <Text className="powered-by-launchnotes">
          <PoweredBy
            color={secondaryTextColor}
            hoverColor={primaryTextColor}
          />
        </Text>
      </Row>
    </Space>
  );
};

export default FeedbackForm;
