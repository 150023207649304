import React from 'react';

const Cactus = ({ theme }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    height="120"
    width="120"
    viewBox="0 0 1000 1000"
    fill={theme.primaryColor}
  >
    <g>
      <path
        className="st0"
        fill={theme.lightGrayColor}
        d="M835.46,138.95c-35.87-1.05-71.74-2.09-107.61-3.14c-13.58-0.4-28.12-0.54-39.52,6.85
                c-13.56,8.79-19.06,28.1-12.17,42.71s25.29,22.65,40.7,17.78c-9.46-1.87-18.51,7.33-19.12,16.96s5.13,18.66,12.34,25.07
                c14.65,13.01,36.33,17.55,54.97,11.51c18.64-6.04,33.53-22.44,37.77-41.56c13.83,25.58,47.35,38.51,74.77,28.84
                c27.42-9.67,45.42-40.76,40.16-69.35c13.32,0.99,26.98,1.95,39.8-1.81c12.82-3.76,24.81-13.31,27.54-26.39
                c4.27-20.46-16.49-39.23-37.31-41.05c-20.83-1.81-40.69,8.04-59.27,17.63c-14.85-37.65-65.77-55.69-101-35.79
                c-11.07,6.25-20.67,15.98-24.36,28.15s-0.41,26.8,9.7,34.51"
      />
    </g>
    <g>
      <path
        className="st0"
        fill={theme.lightGrayColor}
        d="M207.51,289.08c-23.92-51.76-86.35-79.12-123.27-19.5c-11.89,19.2-8.76,48.82,9.89,60.87
                c-141.04-27.94-78.8,209.73,60.43,66.89c28.17,66.93,107.67,23.51,108.3-36.81c85.89,89.8,204.85-44.91,64.3-59.42
                c65.95-47.48-96.35-170.4-124.42-13.24"
      />
    </g>
    <path
      className="st0"
      fill={theme.lightGrayColor}
      d="M203.41,776.26c5.08,23.07,20.77,42.09,38.15,57.36c39.57,34.79,91.97,57.29,142.61,70.29
                c46.42,11.91,95.6,16.39,143.4,17.03c95.07,1.27,214.15-20.18,262.11-115.47c22.81-45.31,24.08-125.98-2.59-160.08
                c-14.33-18.32-34.41-30.4-56.49-37.06c-32.5-9.8-65.74-7.12-98.15,1.64c-37.71,10.2-73.32,27.34-111.45,36.05
                c-35.54,8.13-72.58,5.99-108.73,5.09c-35.92-0.89-72.45-1.5-107.49,7.64c-32.09,8.37-62.91,28.44-83.77,54.3
                C206.59,730.91,198.37,753.33,203.41,776.26z"
    />
    <g>
      <path
        className="st1"
        fill={theme.secondaryTextColor}
        d="M484.55,851.03c-15.16-199.19-23.37-398.91-24.61-598.67c-0.15-24.8-0.05-50.41,9.73-73.21
                c9.78-22.79,31.86-42.21,56.63-40.93c26.47,1.37,52.8,22.87,57.61,48.94c19.82,107.44,13.22,168.01,7.23,253.39
                c-9.84,140.35,27.9,281.77,9.63,421.27c-0.5,3.79-1.16,7.84-3.78,10.62c-2.55,2.7-6.42,3.62-10.07,4.31
                c-22.7,4.26-46.19,4.27-68.88,0.01c-14.59-2.74-32.54-14.22-26.69-27.86"
      />
    </g>
    <g>
      <path
        className="st1"
        fill={theme.secondaryTextColor}
        d="M507.66,475.33c-41.66,5.32-72.82-35.26-80.66-73.04c-14.7-57.83-5.23-126.85-41.94-111.47
                c-24.59,7.2-41.34,29.71-48.83,54.66c-17.69,120.01,38.89,262.74,173.37,225.07"
      />
    </g>
    <g>
      <path
        className="st1"
        fill={theme.secondaryTextColor}
        d="M586.96,345c25.45,4.3,49.86-15.03,61.27-38.18c11.42-23.14,13.29-49.62,17.56-75.07
                c2.46-14.62,6.71-30.54,19.05-38.75c14.75-9.81,35.93-3.86,47.96,9.15s16.73,31.12,19.26,48.65c8.72,60.58-6.46,127.9-52.84,167.84
                c-39.35,33.89-93.77,43.09-145.39,48.7"
      />
    </g>
    <g>
      <defs>
        <path
          id="SVGID_1_"
          d="M203.41,776.26c5.08,23.07,20.77,42.09,38.15,57.36c39.57,34.79,91.97,57.29,142.61,70.29
                    c46.42,11.91,95.6,16.39,143.4,17.03c95.07,1.27,214.15-20.18,262.11-115.47c22.81-45.31,24.08-125.98-2.59-160.08
                    c-14.33-18.32-34.41-30.4-56.49-37.06c-32.5-9.8-65.74-7.12-98.15,1.64c-37.71,10.2-73.32,27.34-111.45,36.05
                    c-35.54,8.13-72.58,5.99-108.73,5.09c-35.92-0.89-72.45-1.5-107.49,7.64c-32.09,8.37-62.91,28.44-83.77,54.3
                    C206.59,730.91,198.37,753.33,203.41,776.26z"
        />
      </defs>
      <g className="st2">
        <path
          className="st3"
          fill={theme.grayColor}
          d="M152.44,900.76c56.14-21.66,116.02-33.59,176.18-35.09c21.26-0.53,42.76,0.21,63.52-4.39
                c20.72-4.59,39.88-14.31,59.57-22.25c48.98-19.75,102.34-28.57,155.07-25.66c29.7,1.64,59.11,6.94,88.82,8.32
                c34.72,1.62,69.45-2.13,104.02-5.74c12.37-1.29,26.08-2.22,35.98,5.3c6.73,5.11,10.42,13.34,12.2,21.6
                c6.66,31.04-11.3,63.33-36.8,82.23c-25.5,18.9-57.28,26.94-88.4,33.19c-84.3,16.94-170.25,23.87-255.95,30.76
                c-46.62,3.75-93.84,7.48-139.91-0.6c-32.41-5.68-63.49-17.08-94.38-28.43C201,948.5,164.9,931.55,159.41,898.6"
        />
      </g>
    </g>
    <g>
      <rect
        fill={theme.secondaryColor}
        x="612.82"
        y="403.66"
        transform="matrix(0.9343 -0.3565 0.3565 0.9343 -121.6554 271.1403)"
        className="st4"
        width="123.92"
        height="123.92"
      />
      <polygon
        fill={theme.whiteColor}
        className="st5"
        points="654.51,381.69 653.46,384.03 651.11,382.98 650.06,385.33 647.72,384.28 646.66,386.63 644.32,385.58
                643.26,387.92 640.92,386.87 639.87,389.22 637.52,388.17 636.47,390.52 634.12,389.47 633.07,391.82 648.66,432.68 651.01,433.73
                652.06,431.39 654.41,432.44 655.46,430.09 657.81,431.14 658.86,428.79 661.21,429.84 662.26,427.49 664.6,428.55 665.66,426.2
                668,427.25 669.06,424.9 671.4,425.95 672.38,423.78 656.69,382.66 	"
      />
      <path
        stroke={theme.strokeColor}
        className="st6"
        d="M638.5,469.51c5.24,11.86,12,23.06,20.04,33.23c-2.16-14.76,3.13-29.9,0.59-44.6"
      />
      <path
        stroke={theme.strokeColor}
        className="st6"
        d="M675.12,444.54c3.96-6.08,9.66-11.01,16.25-14.05c1.97-0.91,4.16-1.66,6.28-1.21
                c3.02,0.65,5.05,3.61,5.81,6.61c1.14,4.47,0.14,9.21-1.29,13.6c-5.09,15.61-15.49,29.03-20.85,44.56
                c11.6-4.99,23.35-9.63,35.22-13.91"
      />
    </g>
  </svg>
);

export default Cactus;
