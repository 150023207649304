import React, { FC, useState } from 'react';

const CloseIcon: FC<{ color: string, hoverColor: string }> = ({ color, hoverColor }) => {
  const [iconColor, setIconColor] = useState<string>(color);

  return (
    <span
      className="close-icon"
      onMouseEnter={() => setIconColor(hoverColor)}
      onMouseLeave={() => setIconColor(color)}
      style={{
        color: iconColor,
        backgroundColor: 'inherit',
        border: 'none',
        fontSize: '1.5rem',
      }}
    >
      x
    </span>
  );
};

export default CloseIcon;
