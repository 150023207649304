import React, { FC, useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useKeyPress, useDebounceFn } from 'ahooks';
import { ProjectContext } from 'components/Ideas/index';
import useToggleVote from 'components/core/hooks/useToggleVote';
import { Button, Space, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
} from '@fortawesome/pro-regular-svg-icons'

const { Text } = Typography;

type VoteIndicatorProps = {
  ideaId: string;
  detailView?: boolean;
  mobileView?: boolean;
  showAuthView: (boolean) => void;
  setAuthTrigger?: (string) => void;
  openDrawer?: (boolean) => void;
}

const VoteIndicator: FC<VoteIndicatorProps> = ({ ideaId, detailView, mobileView, showAuthView, openDrawer, setAuthTrigger }) => {
  const {
    pathPrefix,
    canVote,
    primaryColor,
    primaryTextColor,
    voteCountDisplay,
    whiteColor,
  } = useContext(ProjectContext);

  const queryClient = useQueryClient();
  const [toggleVote] = useToggleVote(pathPrefix);

  const cachedVote = useCallback(() => {
    const data = queryClient.getQueryData(['idea', 'list']);
    const idea = data.find(i => i.id === ideaId);
    return { count: idea.vote_count, voted: idea.has_voted };
  }, [ideaId]);

  const { run } = useDebounceFn(
    () => toggleVote.mutate([ideaId]),
    { wait: 200 },
  );

  const handleVote = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!canVote) {
      if (setAuthTrigger) setAuthTrigger('vote');
      if (openDrawer) openDrawer(true);
      showAuthView(true);
    } else {
      run();
    }
  };

  useKeyPress(['uparrow', 'v'], (event) => {
    if (!detailView) return;
    if (document.getElementsByClassName('ant-modal-wrap')[0]?.style?.display === 'none') return;
    if (['INPUT', 'TEXTAREA'].includes(event.target?.tagName)) return;

    handleVote(event);
  });

  if (mobileView) {
    return (
      <Space onClick={(e) => handleVote(e)}>
        <FontAwesomeIcon icon={faHeart}
          height={22}
          selected={cachedVote().voted}
          borderColor={primaryColor}
          backgroundColor={primaryColor}
        />
        { voteCountDisplay === 'exact' &&
          <Text type="secondary">{cachedVote().count}</Text>
        }
      </Space>
    );
  }

  const ListView = (
    <Space onClick={(e) => handleVote(e)}>
      <FontAwesomeIcon icon={faHeart}
        height={16}
        selected={cachedVote().voted}
        borderColor={primaryColor}
        backgroundColor={primaryColor}
        className="primary-color"
      />
      <Text
        className="vote-count primary-text-color"
        style={{ color: primaryTextColor }}
      >
        {voteCountDisplay === 'exact' ? cachedVote().count : `Vote${cachedVote().voted ? 'd' : ''}`}
      </Text>
    </Space>
  );

  const DetailView = (
    <Space className="mt-4">
      <Button
        onClick={(e) => handleVote(e)}
        className="vote-button"
        style={{ backgroundColor: cachedVote().voted ? primaryColor : whiteColor }}
      >
        <FontAwesomeIcon icon={faHeart}
          height={16}
          selected={cachedVote().voted}
          backgroundColor={whiteColor}
          borderColor={primaryColor}
        />
        <Text
          className="vote-button-label ml-2"
          style={{ color: cachedVote().voted ? whiteColor : `${primaryColor}` }}
        >
          {cachedVote().voted ? 'Voted' : 'Vote'}
        </Text>
      </Button>
      {voteCountDisplay === 'exact' &&
        <Text style={{ color: primaryTextColor }}>
          {cachedVote().count} vote{cachedVote().count === 1 ? '' : 's'}
        </Text>
      }
    </Space>
  );

  return (
    <Space className="vote-indicator">
      { detailView ? DetailView : ListView }
    </Space>
  );
};

export default VoteIndicator;
