import React, { FC, useContext } from 'react';
import NavArrows from 'components/Ideas/NavArrows';
import VoteIndicator from 'components/Ideas/VoteIndicator';
import SpeechBubble from 'components/Ideas/assets/SpeechBubble';
import { ProjectContext } from 'components/Ideas/index';
import {
  Affix,
  Col,
  Divider,
  Layout,
  Row,
  Space,
} from 'antd';

type MobileNavbarProps = {
  id: string;
  listIdea: LN.Idea;
  onChange: (id: string) => void;
  updateShowAuthView: (boolean) => void;
  setAuthTrigger: (string) => void;
  setDrawerOpen?: (boolean) => void;
}

const MobileNavbar: FC<MobileNavbarProps> = ({ id, listIdea, onChange, updateShowAuthView, setDrawerOpen, setAuthTrigger }) => {
  const context = useContext(ProjectContext);
  const actionsEnabled = context.votingEnabled || context.feedbackEnabled;

  return (
    <Affix
      className="ideas-mobile-nav"
      style={{ backgroundColor: context.whiteColor }}
    >
      <Divider className="my-2" style={{ color: context.lightGrayColor }} />
      <Row className="pt-2">
        <Col span={actionsEnabled ? 11 : 24}>
          <NavArrows
            mobileView
            listIdea={listIdea}
            onChange={onChange}
          />
        </Col>
        {actionsEnabled &&
          <>
            <Col span={2}>
              <Divider type="vertical" style={{ color: context.lightGrayColor }} />
            </Col>
            <Col span={11} className="ideas-mobile-actions">
              <Row>
                {context.votingEnabled &&
                  <Col span={context.feedbackEnabled ? 12 : 24}>
                    <VoteIndicator
                      mobileView
                      ideaId={id}
                      showAuthView={updateShowAuthView}
                      setAuthTrigger={setAuthTrigger}
                      openDrawer={setDrawerOpen}
                    />
                  </Col>
                }
                {context.feedbackEnabled &&
                  <Col span={context.votingEnabled ? 12 : 24}>
                    <Space
                      onClick={() => {
                        setAuthTrigger('feedback');
                        setDrawerOpen(true);
                      }}
                    >
                      <SpeechBubble
                        fill={context.primaryColor}
                        style={{ fontSize: '22px' }}
                      />
                    </Space>
                  </Col>
                }
              </Row>
            </Col>
          </>
        }
      </Row>
    </Affix>
  );
};

export default MobileNavbar;
