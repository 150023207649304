import React, { useContext, useEffect, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useKeyPress } from 'ahooks';
import { ProjectContext } from 'components/Ideas/index';
import RightArrow from 'components/Ideas/assets/RightArrow';
import {
  Button,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';

const { Text, Title } = Typography;

export type VoteTriggers = 'vote'|'feedback';

const AuthPrompt = ({ trigger }:{trigger:VoteTriggers}) => {
  const {
    pathPrefix,
    feedbackEnabled,
    primaryColor,
    privacyPolicyUrl,
    projectName,
    recaptchaSiteToken,
    termsAndConditionsUrl,
    votingEnabled,
    whiteColor,
  } = useContext(ProjectContext);

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [email, setEmail] = useState<string | null>();
  const [token, setToken] = useState<string | null>();
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    recaptchaRef.current && recaptchaRef.current.execute();
  }, [recaptchaRef]);

  const handleSubmit = async () => {
    const validEmail = /(.+)@(.+){2,}\.(.+){2,}/.test(email);

    if (!validEmail) {
      setError('Please provide a valid email.');
    } else if (!token) {
      setError('ReCaptcha failed, please try again.');
    } else {
      setError(null);

      const url = pathPrefix ? `${pathPrefix}/subscribers` : '/subscribers';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'g-recaptcha-response-data': { subscribe: token },
          'g-recaptcha-response': '',
          subscribed_object_type: 'Project',
          subscriber: { email },
          destination: window.location.href,
          override_block_robots: true
        }),
      });

      if (response.ok) {
        const body = await response.json();
        const params = new URLSearchParams({ email: body.subscriberEmail });
        const path = pathPrefix ? `${pathPrefix}/updates/check_your_inbox?${params}` : `/updates/check_your_inbox?${params}`;
        window.Turbo.visit(path, { action: 'replace' });
      } else {
        const { error } = await response.json();
        setError('We were unable to create a subscription for you');
        console.log(error);
      }
    }
  };

  useKeyPress('enter', () => email && handleSubmit());

  const titleText = trigger === 'feedback' ?
    'Get a magic link to leave feedback' :
    'Get a magic link to vote';

  let actionMessage: string;
  if (feedbackEnabled && votingEnabled) {
    actionMessage = 'vote, leave feedback,';
  } else if (feedbackEnabled) {
    actionMessage = 'leave feedback';
  } else {
    actionMessage = 'vote';
  }

  return (
    <Space direction="vertical" className="idea-auth-sidebar h-100 w-100">
      <Row>
        <Title level={3} style={{ color: whiteColor }}>
          {titleText}
        </Title>
      </Row>
      <Row className="mb-2">
        <Text className="mb-2" style={{ color: whiteColor }}>
          {projectName} page subscribers can {actionMessage} and optionally stay informed on product changes.
        </Text>
        <Text className="mb-2" style={{ color: whiteColor }}>
          Get a magic link sent to your email that will sign you in instantly.
        </Text>
        <Input
          className="subscriber-email-input"
          placeholder="Email address"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          style={{
            color: whiteColor,
            borderColor: whiteColor,
          }}
        />
        { error &&
          <Text className="subscriber-email-error" style={{ color: whiteColor }}>
            {error}
          </Text>
        }
      </Row>
      <Row className="mb-2">
        <Button
          className="subscriber-email-submit w-100"
          onClick={() => handleSubmit()}
          style={{
            backgroundColor: whiteColor,
            color: primaryColor,
          }}
        >
          <Text style={{ color: primaryColor }}>Send magic link &nbsp;<RightArrow /></Text>
        </Button>
        <Text
          className="idea-auth-terms mt-1"
          style={{ color: whiteColor }}
        >
          By clicking send magic link, you accept our
          <a href={privacyPolicyUrl} target="_blank" rel="noreferrer"> privacy policy </a>
          and
          <a href={termsAndConditionsUrl} target="_blank" rel="noreferrer"> terms and conditions. </a>
          <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">ReCAPTCHA privacy </a>
          and
          <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer"> terms apply.</a>
        </Text>
      </Row>
      <ReCAPTCHA
        ref={recaptchaRef}
        onChange={(tkn: string) => setToken(tkn)}
        sitekey={recaptchaSiteToken}
        size="invisible"
      />
    </Space>
  );
};

export default AuthPrompt;
