import WebpackerReact from 'webpacker-react';
import 'styles/public';
import LocalTime from 'local-time';
import Alerts from 'components/Alerts';
import Ideas from 'components/Ideas';
import RssFeedButton from 'components/RssFeedButton';

LocalTime.start();

WebpackerReact.setup({
  Alerts,
  Ideas,
  RssFeedButton,
});
