import { useCallback } from 'react';

export type FeedbackAttrs = {
  project: string;
  feedbackable_id: string;
  feedbackable_type: string;
  content: string;
  email?: string;
  reaction?: string;
  importance?: string;
}

const useSubmitFeedback = (path: string) => {
  const submitFeedback = useCallback((attrs:FeedbackAttrs) => {
    return fetch(path, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        feedback: attrs,
      }),
      method: 'post',
    });
  }, []);

  return [submitFeedback];
};

export default useSubmitFeedback;

