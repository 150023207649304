import React from 'react';

const MagnifyingLongHair = ({ theme }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    height="120"
    width="120"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
  >

    <path
      fill={theme.grayColor}
      className="st00"
      d="M214.25,882.72c22.06,22.45,52.97,38.54,84,43.47c33.88,5.39,68.27,1.44,102.06-2.72
	c34.01-4.18,68.73-9.62,103-5.24c36.76,4.7,71.92,17.58,108.42,23.71c31.36,5.27,62.96,4.73,92.7-7.51
	c20.21-8.32,38.03-21.56,49.85-40.16c22-34.61,13.35-110.56-12.33-151.18c-54.02-85.42-168.28-94.67-257.81-84.69
	c-45.02,5.01-90.97,13.77-133.65,29.29c-46.54,16.92-93.88,42.97-127.98,79.44c-14.97,16-28.02,35.39-30.68,57.61
	C189.19,846.82,199.01,867.2,214.25,882.72z"
    />
    <path stroke="#F3DD68" className="st1" d="M326.15,466.76" />
    <path
      fill={theme.lightGrayColor}
      className="st2"
      d="M520.51,197.19c0,0,24.98,167.66-13.91,280.35s-79.41,67.08-167.66,14.1S307.06,225.9,307.06,225.9
	L520.51,197.19z"
    />
    <path
      fill={theme.lightGrayColor}
      className="st3"
      d="M304.69,707.48c14.7-35.52,27.91-64.95,32.46-82.84c12.84-50.43-11-177.04-11-177.04s74.27-32.92,66.02,51.43
	c-4.83,49.4,9.21,111.7,33.09,168.29c16.89,40.03,38.7,77.21,62.25,104.94c31.3,36.87,45.37,101.83,51.7,152.75
	c-11.86-2.79-23.78-5.23-35.89-6.78c-34.27-4.38-68.99,1.05-103,5.24c-33.79,4.16-68.18,8.1-102.06,2.72
	c-15.85-2.52-31.67-7.96-46.29-15.67C251.61,842.7,280.21,766.64,304.69,707.48z"
    />
    <g>
      <path
        fill={theme.secondaryTextColor}
        className="st0"
        d="M487.22,123.18c-11.36-2.7-125.38-69.92-230.34,2.33c-31.41,21.62-72.82,86.78-81.46,128.97
		c-6.59,32.19-4.8,65.42-6.82,98.22c-5,81.16-34.12,160.67-82.61,225.94c-2.76,3.71-1.27,9.03,3.03,10.73
		c14.27,5.63,29.83,7.99,45.12,6.81c3.52-0.27,6.66,2.11,7.4,5.56c1.17,5.47,2.91,10.81,5.2,15.91c1.35,3.02,4.63,4.73,7.87,4.04
		c17.73-3.76,34.78-10.72,50.08-20.44c3.28-2.08,7.67-1.23,9.73,2.07c2.93,4.69,5.04,9.88,6.22,15.28c0.97,4.41,5.66,6.88,9.78,5.02
		l50.7-22.93c33.74-15.26,69.5-32.25,88.67-63.94c16.49-27.27,17.55-60.34,12.83-92.43c4.1-13.14,8.58-24.82,13.22-32.61
		c18.63-31.26,26.32-41.7,26.32-41.7s12.7-62.28,21.46-87.63c6.51-18.83,47.01-59.32,64.72-75.02
		C537.98,181.07,525.77,132.34,487.22,123.18z"
      />
    </g>
    <path fill={theme.lightGrayColor} className="st2" d="M433.86,357.95c0,0-24.03-90.15-86.54-72.41s-8.84,178.23,44.86,161.64S433.86,357.95,433.86,357.95z" />
    <line stroke={theme.primaryTextColor} className="st4" x1="679.29" y1="500.27" x2="529.87" y2="687.08" />
    <path
      fill={theme.lightGrayColor}
      className="st2"
      d="M554.26,749.33c10.85-46.9,18.45-75.31,21.79-92.63c3.25-16.9-4.1-19.51-3.38-25.85
	c16.6-146.42,96.97-73.86,82.59-34.64c-7.29,19.89-26.18,38.66-34.62,67.39c-5.43,18.5-8.59,40.05-7.51,65.55
	c2.06,48.76-29.02,153.59-44.75,203.14c-21.54-5.48-43.02-11.25-65.07-14.07c-2.72-0.35-5.45-0.61-8.18-0.84
	C508.97,887.03,538.26,818.47,554.26,749.33z"
    />
    <g>
      <path
        fill="#F2F2F2"
        className="st5"
        d="M737.05,276.25c-91.72,28.77-127.54,179.66-32.85,225.58c32,12.21,75.66,10.59,99.31,6.99
		c24.44-4.8,85.5-39.14,93.09-117.87c7.4-76.79-87.99-115.07-132.26-118.54C757.48,271.88,743.63,274.18,737.05,276.25z"
      />
      <g>
        <path
          fill={theme.primaryTextColor}
          className="st6"
          d="M732.27,258.89c-12.89,4.06-25.31,10.11-36.22,18.1c-11.03,8.08-20.93,17.23-29.32,28.04
			c-4.66,6-9.1,12.09-12.96,18.63c-3.12,5.3-5.92,10.79-8.38,16.43c-5.51,12.64-9.94,25.56-12.28,39.18
			c-2.29,13.33-3.41,26.87-2.15,40.37c0.68,7.29,1.73,14.61,3.47,21.74c1.74,7.11,4.32,13.83,7.19,20.55
			c2.27,5.32,4.98,10.45,8.16,15.29c4,6.1,8.57,11.94,13.63,17.19c5.23,5.42,11.18,10.19,17.33,14.52
			c6.71,4.72,13.95,8.67,21.71,11.36c8.4,2.92,17.14,4.85,25.93,6.16c10.34,1.55,20.71,2.6,31.17,2.88
			c8.38,0.23,16.78,0.05,25.14-0.51c3.66-0.25,7.32-0.57,10.96-0.97c4.42-0.49,8.93-0.88,13.28-1.8c3.34-0.71,6.61-1.76,9.8-2.98
			c5.42-2.07,10.78-4.41,15.91-7.13c10.76-5.71,20.76-13.08,29.8-21.2c4.64-4.17,8.99-8.65,13.01-13.41
			c5.26-6.23,10.21-12.8,14.49-19.74c4.6-7.45,8.24-15.31,11.6-23.39c3.04-7.3,5.44-14.86,7.21-22.56
			c0.95-4.14,1.73-8.32,2.33-12.53c0.78-5.47,1.58-10.98,1.84-16.5c0.38-8.17-0.48-16.42-2.05-24.43c-1.5-7.68-4.28-14.93-7.5-22.04
			c-2.54-5.63-5.71-10.97-9.34-15.97c-4.54-6.25-9.45-12.22-14.98-17.62c-5.58-5.45-11.71-10.32-17.98-14.95
			c-10.21-7.54-21.34-13.81-32.9-19.01c-12.04-5.41-24.4-10.09-37.2-13.36c-4.87-1.24-9.79-2.29-14.75-3.08
			c-6.47-1.02-13.12-2.12-19.69-1.68c-5.41,0.36-10.81,1.14-16.14,2.09C738.98,257.16,735.57,257.86,732.27,258.89
			c-4.41,1.37-8.4,4.24-10.76,8.27c-1.32,2.07-2.03,4.3-2.14,6.69c-0.54,2.39-0.43,4.79,0.32,7.18c1.41,4.36,4.11,8.56,8.27,10.76
			c4.21,2.22,9.2,3.26,13.87,1.81c4.92-1.53,10.07-2.42,15.17-3.12c-1.6,0.21-3.19,0.43-4.79,0.64c3.75-0.5,7.58-0.93,11.37-0.75
			c2.28,0.11,4.55,0.4,6.81,0.7c-1.6-0.21-3.19-0.43-4.79-0.64c12.06,1.63,23.92,4.82,35.37,8.9c3.68,1.31,7.33,2.73,10.93,4.25
			c-1.43-0.6-2.87-1.21-4.3-1.81c10.99,4.64,21.6,10.21,31.51,16.86c2.85,1.91,5.63,3.91,8.35,6.01c-1.21-0.94-2.43-1.88-3.64-2.81
			c8.23,6.37,15.8,13.61,22.18,21.85c-0.94-1.21-1.88-2.43-2.81-3.64c4.81,6.25,8.89,13.04,11.96,20.31
			c-0.6-1.43-1.21-2.87-1.81-4.3c2.75,6.57,4.64,13.47,5.59,20.53c-0.21-1.6-0.43-3.19-0.64-4.79c1.1,8.36,0.74,16.69-0.37,25.03
			c0.21-1.6,0.43-3.19,0.64-4.79c-1.72,12.79-5.01,25.33-10.01,37.23c0.6-1.43,1.21-2.87,1.81-4.3
			c-4.54,10.73-10.44,20.85-17.57,30.08c0.94-1.21,1.88-2.43,2.81-3.64c-7.05,9.09-15.24,17.27-24.33,24.32
			c1.21-0.94,2.43-1.88,3.64-2.81c-6.73,5.2-13.93,9.8-21.56,13.56c-1.83,0.9-3.68,1.75-5.56,2.54c1.43-0.6,2.87-1.21,4.3-1.81
			c-5.37,2.26-10.83,3.98-16.61,4.76c1.6-0.21,3.19-0.43,4.79-0.64c-16.21,2.16-32.7,2.68-49.02,1.68
			c-5.19-0.32-10.37-0.81-15.53-1.51c1.6,0.21,3.19,0.43,4.79,0.64c-10.73-1.46-21.39-3.78-31.53-7.63c1.43,0.6,2.87,1.21,4.3,1.81
			c-6.29-3.06-12.27-6.71-17.81-10.97c1.21,0.94,2.43,1.88,3.64,2.81c-6.35-4.91-12.07-10.61-16.98-16.96
			c0.94,1.21,1.88,2.43,2.81,3.64c-4.99-6.5-9.11-13.63-12.31-21.17c0.6,1.43,1.21,2.87,1.81,4.3c-3.8-9-6.28-18.5-7.59-28.17
			c0.21,1.6,0.43,3.19,0.64,4.79c-1.21-9.07-1.4-18.26-0.69-27.38c0.2-2.61,0.48-5.22,0.83-7.82c-0.21,1.6-0.43,3.19-0.64,4.79
			c1.39-10.24,3.85-20.33,7.3-30.07c1-2.81,2.08-5.6,3.23-8.35c-0.6,1.43-1.21,2.87-1.81,4.3c3.89-9.2,8.65-18.04,14.28-26.29
			c1.6-2.34,3.26-4.63,4.99-6.88c-0.94,1.21-1.88,2.43-2.81,3.64c6.78-8.75,14.57-16.7,23.34-23.47c-1.21,0.94-2.43,1.88-3.64,2.81
			c7.67-5.9,16.03-10.86,24.94-14.63c-1.43,0.6-2.87,1.21-4.3,1.81c3.05-1.28,6.16-2.42,9.32-3.42c4.42-1.39,8.39-4.22,10.76-8.27
			c1.32-2.07,2.03-4.3,2.14-6.69c0.54-2.39,0.43-4.79-0.32-7.18c-1.41-4.36-4.11-8.56-8.27-10.76
			C741.94,258.49,736.92,257.42,732.27,258.89z"
        />
      </g>
    </g>
    <path
      fill={theme.secondaryColor}
      className="st7"
      d="M304.69,707.48c12.51-30.23,23.94-56.05,29.87-74.1c35.77,4.23,60.51,1.65,76.96-2.53
	c4.08,12.29,8.69,24.5,13.74,36.46c16.89,40.03,38.7,77.21,62.24,104.95c31.3,36.87,45.37,101.83,51.7,152.75
	c-11.86-2.79-23.78-5.23-35.89-6.78c-34.27-4.38-68.99,1.05-103,5.24c-33.79,4.16-68.18,8.1-102.06,2.72
	c-15.85-2.52-31.67-7.97-46.3-15.67C251.61,842.69,280.21,766.64,304.69,707.48z"
    />
  </svg>
);

export default MagnifyingLongHair;
