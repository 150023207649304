import React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const useAlerts = () => ({
  success: React.useCallback((message) => {
    toast.success(() => (
      <>
        <strong>Success!</strong><br />
        {message}
      </>
    ));
  }, []),
  error: React.useCallback((message) => {
    toast.error(() => (
      <>
        <strong>Uh oh!</strong><br />
        {message}
      </>
    ));
  }, []),
});

const Alerts = ({ initialAlerts = [] }) => {
  const alerts = useAlerts();

  React.useEffect(() => {
    initialAlerts.forEach(({ type, message }) => {
      if (type === 'success') {
        alerts.success(message);
      } else {
        alerts.error(message);
      }
    });
  }, [initialAlerts]);

  return (
    <ToastContainer
      theme="colored"
      position="bottom-left"
      transition={Slide}
      limit={3}
      autoClose={4000}
      closeButton={false}
      closeOnClick={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      hideProgressBar
      draggable={false}
      icon={false}
    />
  );
};

export default Alerts;
