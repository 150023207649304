import React, { useContext } from 'react';
import AuthPrompt from 'components/Ideas/AuthPrompt';
import CloseIcon from 'components/core/CloseIcon';
import { ProjectContext } from 'components/Ideas/index';
import { ConfigProvider, Modal } from 'antd';

type AuthModalProps = {
  visible: boolean;
  onClose: (arg0: boolean) => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ visible, onClose }) => {
  const {
    offWhiteColor,
    primaryColor,
    whiteColor,
  } = useContext(ProjectContext);

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: primaryColor,
          },
        },
      }}
    >
      <Modal
        className="idea-auth-modal"
        centered
        open={visible}
        onCancel={() => onClose(false)}
        footer={null}
        zIndex={1200}
        bodyStyle={{ padding: '2rem 3rem' }}
        getContainer={document.getElementById('ideas-container-component')}
        closeIcon={<CloseIcon color={offWhiteColor} hoverColor={whiteColor} />}
      >
        <AuthPrompt trigger="vote" />
      </Modal>
    </ConfigProvider>
  );
};

export default AuthModal;
