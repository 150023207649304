import React, { FC, useContext } from 'react';
import { useKeyPress } from 'ahooks';
import LeftArrow from './assets/LeftArrow';
import RightArrow from './assets/RightArrow';
import { ProjectContext } from 'components/Ideas/index';
import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd';

const { Text } = Typography;

type NavArrowProps = {
  mobileView?: boolean
  listIdea: LN.Idea;
  onChange: (id: string) => void;
}

const NavArrows: FC<NavArrowProps> = ({ mobileView, listIdea, onChange }) => {
  const context = useContext(ProjectContext);

  const handleKeyPress = (idea: string, event: KeyboardEvent) => {
    // If the keystroke originates from an input, it should be ignored.
    // Don't let typescript lie to you, tagName exists on `event.target`
    if (['INPUT', 'TEXTAREA'].includes(event.target?.tagName)) return;
    if (!idea) return;

    onChange(idea);
  };

  useKeyPress(['leftarrow', 'h'], (event) => handleKeyPress(listIdea.previous_idea, event));
  useKeyPress(['rightarrow', 'l'], (event) => handleKeyPress(listIdea.next_idea, event));

  return (
    <Row className="idea-navbar">
      <Col span={12} style={{ textAlign: mobileView ? 'center' : 'left' }}>
        {listIdea?.previous_idea
          && (
            <Button
              type="link"
              style={{ color: context.primaryColor }}
              onClick={() => onChange(listIdea.previous_idea)}
            >
              {!mobileView &&
                <Row className="mb-1">
                  <Text className="instructions" style={{ color: context.secondaryTextColor }}>
                    H / left-arrow
                  </Text>
                </Row>
              }
              <Row align="middle">
                <Col style={{ bottom: 1 }}>
                  <LeftArrow size={mobileView ? '1.5em' : '1em'} />
                </Col>
                {!mobileView && <Col className="ml-2">Previous idea</Col> }
              </Row>
            </Button>
          )}
      </Col>
      <Col span={12} style={{ textAlign: mobileView ? 'center' : 'right' }}>
        {listIdea?.next_idea
          && (
            <Button
              type="link"
              style={{ color: context.primaryColor }}
              onClick={() => onChange(listIdea.next_idea)}
            >
              {!mobileView &&
                <Row className="mb-1">
                  <Text className="instructions" style={{ color: context.secondaryTextColor }}>
                    L / right-arrow
                  </Text>
                </Row>
              }
              <Row align="middle">
                {!mobileView && <Col className="mr-2">Next idea</Col> }
                <Col style={{ bottom: 1 }}>
                  <RightArrow size={mobileView ? '1.5em' : '1em'} />
                </Col>
              </Row>
            </Button>
          )}
      </Col>
    </Row>
  );
};

export default NavArrows;
