import { message } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';

const useToggleVote = (pathPrefix: string) => {
  const queryClient = useQueryClient();

  const toggleVote = useMutation({
    mutationFn: ([ideaId]: string[]) => {
      const url = pathPrefix ?
        `${pathPrefix}/ideas/${ideaId}/toggle_vote.json` :
        `/ideas/${ideaId}/toggle_vote.json`;

      return fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(response => response.json())
        .then(body => {
          const listData = queryClient.getQueryData(['idea', 'list']);
          if (listData) {
            const index = listData.map(idea => idea.id).indexOf(ideaId);
            listData[index].vote_count = body.vote_count;
            listData[index].has_voted = body.voted;
            queryClient.setQueryData(['idea', 'list'], listData);
          }
        })
        .catch((error) => {
          message.error('We were unable to cast your vote' + error);
        });
    }
  })

  return [toggleVote];
};

export default useToggleVote;
